.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.headerH1 h1{
  font-weight: 900 !important;
  font-size: 80px !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.makeStyles-title-3{
  color: #DC581F !important;
  text-shadow: white 0px 0px 10px;
  font-size: 80px !important;
  font-weight: 600;
}

.makeStyles-subtitle-4{
  color: #1D1D1B !important;
  text-shadow: white 0px 0px 10px;
  font-size: 28px !important;
  max-width: 600px;
  font-weight:900;
}

.MuiButton-label{
  color: #DC581F !important;
  font-weight:900;
}

h2{
  color: #1D1D1B !important;
  font-weight:900;
}

.makeStyles-title-282{
  color: #DC581F !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
}

.jss282{
  color: #DC581F !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
}

.makeStyles-primary-138{
  background-color: #1D1D1B !important;
}

.jss138{
  background-color: #1D1D1B !important;
}

.makeStyles-rose-143{
  background-color: #1D1D1B !important;
}

.jss143{
  background-color: #1D1D1B !important;
}

.jss144{
  background-color: #1D1D1B !important;
}

.jss130{
  background-color: #1D1D1B !important;
}

